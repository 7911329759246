html body {
  height:100%; 
  width: 100%; 
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.highcharts-figure, .highcharts-data-table table {
  min-width: 310px; 

  margin: 1em auto;
}

#container {
  height: 90vh;
  width: 100vw;
  position: relative; 
}

.highcharts-data-table table {
font-family: Verdana, sans-serif;
border-collapse: collapse;
border: 1px solid #EBEBEB;
margin: 10px auto;
text-align: center;
width: 100%;
max-width: 500px;
}
.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}
.highcharts-data-table th {
font-weight: 600;
  padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
  padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}
.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

.gm-loader{
  position: absolute; 
  width: 100%; 
  height: 100%; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gm-menu{
  display: flex; 
  flex-direction: row; 
  justify-content: space-evenly;
  margin-left: 25px; 
  margin-right: 25px;
  margin-top: 10px; 
  margin-bottom: 10px; 
}
.gm-select{
  width: 80%; 
}

.gm-menu button{
  margin-left: 10px; 
}

.gm-menu label{
  margin-left: 10px; 
  margin-right: 10px; 
}




@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
